var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home"}},[_vm._m(0),_c('div',{staticClass:"community"},[_c('a',{staticClass:"community-text",attrs:{"href":"/community/c1/1"}},[_vm._v("ชุมชนบ้านถ้ำ อำเภอดอกคำใต้ จังหวัดพะเยา")]),_c('div',{staticClass:"community-carousel"},[_c('Carousel',{attrs:{"carousel":[
          require(`../../assets/setOfPictures/2.jpg`),
          require(`../../assets/setOfPictures/4.jpg`),
          require(`../../assets/setOfPictures/7.jpg`),
          require(`../../assets/setOfPictures/9.jpg`),
          require(`../../assets/setOfPictures/12.jpg`),
          require(`../../assets/setOfPictures/15.jpg`),
          require(`../../assets/setOfPictures/25.jpg`),
        ]}})],1)]),_c('div',{staticClass:"community"},[_c('a',{staticClass:"community-text",attrs:{"href":"/community/c2/1"}},[_vm._v("ชุมชนศรีถ้อย อำเภอแม่ใจ จังหวัดพะเยา")]),_c('div',{staticClass:"community-carousel"},[_c('Carousel',{attrs:{"carousel":[
          require(`../../assets/identity2/3.jpg`),
          require(`../../assets/legend/2.jpg`),
          require(`../../assets/resource/5.jpg`),
          require(`../../assets/travel/1.jpg`),
        ]}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("โครงการวิจัย การค้นหาอัตลักษณ์ชุมชนผ่านวัฒนธรรม ประเพณี และวิถีชีวิต")])])
}]

export { render, staticRenderFns }