<template>
  <div id="home">
    <div class="title">
      <span
        >โครงการวิจัย การค้นหาอัตลักษณ์ชุมชนผ่านวัฒนธรรม ประเพณี
        และวิถีชีวิต</span
      >
    </div>
    <div class="community">
      <a href="/community/c1/1" class="community-text"
        >ชุมชนบ้านถ้ำ อำเภอดอกคำใต้ จังหวัดพะเยา</a
      >
      <div class="community-carousel">
        <Carousel
          :carousel="[
            require(`../../assets/setOfPictures/2.jpg`),
            require(`../../assets/setOfPictures/4.jpg`),
            require(`../../assets/setOfPictures/7.jpg`),
            require(`../../assets/setOfPictures/9.jpg`),
            require(`../../assets/setOfPictures/12.jpg`),
            require(`../../assets/setOfPictures/15.jpg`),
            require(`../../assets/setOfPictures/25.jpg`),
          ]"
        />
      </div>
    </div>
    <div class="community">
      <a href="/community/c2/1" class="community-text"
        >ชุมชนศรีถ้อย อำเภอแม่ใจ จังหวัดพะเยา</a
      >
      <div class="community-carousel">
        <Carousel
          :carousel="[
            require(`../../assets/identity2/3.jpg`),
            require(`../../assets/legend/2.jpg`),
            require(`../../assets/resource/5.jpg`),
            require(`../../assets/travel/1.jpg`),
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "./Carousel.vue";

export default {
  components: {
    Carousel,
  },
};
</script>


<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
#home {
  padding: 40px 10vw 0 10vw;
  .title {
    text-align: center;
    padding: 20px 0;

    span {
      font-size: 30px;
      margin: 40px 0 50px 0;
      letter-spacing: 0;
      color: $--color-text-primary;
    }
    a {
      font-size: 30px;
      margin: 40px 0 50px 0;
      letter-spacing: 0;
      cursor: pointer;
      color: $--color-text-primary;
      text-decoration: none;
    }
    a:hover {
      color: $--color-primary;
    }
  }

  .community {
    margin-bottom: 60px;
    a.community-text {
      font-size: 24px;
      cursor: pointer;
      color: $--color-text-primary;
      text-decoration: none;
    }
    a.community-text:hover {
      color: $--color-primary;
    }
    .community-carousel {
      margin-top: 20px;
      padding: 0 150px;
    }
  }
}
</style>