<template>
  <el-container>
    <el-header>
      <Header />
    </el-header>
    <el-main>
      <slot></slot>
    </el-main>
    <el-footer>
      <Footer />
    </el-footer>
  </el-container>
</template>



<script>
import Header from "@/components/navMenu/Header";
import Footer from "@/components/navMenu/Footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  padding: 0;
  height: auto !important;
}
.el-main {
  min-height: 10vw;
  padding: 0;
}
.el-footer {
  padding: 0;
}
</style>