<template>
  <div class="header">
    <div class="logo">
      <span class=""
        >P<span class="">H</span><span class="">A</span
        ><span class="color">Y</span><span class="color">A</span
        ><span class="color">O</span><span class="">I</span><span>D</span></span
      >
    </div>
    <div class="menu">
      <div class="item" :class="{ active: $route.name === 'page' }">
        <a href="/">หน้าแรก</a>
      </div>
      <div class="item" :class="{ active: $route.name === 'comunityC1' }">
        <el-dropdown @command="handleCommand" placement="top-start">
          <span class="el-dropdown-link">
            ชุมชนบ้านถ้ำ<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/community/c1/1">SWOT</el-dropdown-item>
            <el-dropdown-item command="/community/c1/2"
              >อัตลักษณ์ชุมชน</el-dropdown-item
            >
            <el-dropdown-item command="/community/c1/3"
              >หลักฐานทางประวัติศาสตร์</el-dropdown-item
            >
            <el-dropdown-item command="/community/c1/4"
              >ชุดภาพกู่เก่า</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="item" :class="{ active: $route.name === 'comunityC2' }">
        <el-dropdown @command="handleCommand" placement="top-start">
          <span class="el-dropdown-link">
            ชุมชนศรีถ้อย<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/community/c2/1">SWOT</el-dropdown-item>
            <el-dropdown-item command="/community/c2/2"
              >อัตลักษณ์ชุมชน</el-dropdown-item
            >
            <el-dropdown-item command="/community/c2/3">ตำนาน</el-dropdown-item>
            <el-dropdown-item command="/community/c2/4"
              >ทรัพยากร</el-dropdown-item
            >
            <el-dropdown-item command="/community/c2/5"
              >สังคมพื้นฐาน</el-dropdown-item
            >
            <el-dropdown-item command="/community/c2/6"
              >สถานที่ท่องเที่ยว</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleCommand(command) {
      this.$router.replace(command);
      // window.location.reload(command);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
.header {
  padding: 10px 10vw;
  .el-image {
    height: 60px;
  }

  .menu {
    display: flex;
    align-items: center;
    background-color: $--color-primary;
    height: 52px;
    .item {
      padding: 0 10px;
      a,
      span {
        color: #fff;
        text-decoration: none;
        font-size: 17px;
        &:hover {
          color: $--color-text-primary;
        }
      }
      &.active {
        a,
        span {
          color: $--color-text-primary;
        }
      }
      .el-dropdown-link {
        cursor: pointer;
      }
    }
  }
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    .menu {
      display: none;
    }
  }
}
</style>