<template>
  <DashboardTemplate>
    <Home />
    <Contact />
  </DashboardTemplate>
</template>

<script>
import DashboardTemplate from "@/template/DashboardTemplate.vue";

import Home from "@/components/page/Home.vue";
import Contact from "@/components/page/Contact.vue";

export default {
  components: {
    DashboardTemplate,
    Home,
    Contact,
  },
};
</script>
